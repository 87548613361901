import React, { useCallback } from "react"
import * as styles from "./payments.module.css"
import InputTitle from "components/tools/InputTitle.js"
// import { useMenu } from "hooks"
// import { payMethod } from "utils/data"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { setPayMethod } from "store/modules/payInfoReducer"
import { payMethodTable } from "utils/data.js"
const MethodSelect = () => {
  const dispatch = useDispatch()

  const setMethodDis = useCallback(
    value => {
      dispatch(setPayMethod(value))
    },
    [dispatch]
  )

  const { clicked, payMethod } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  return (
    <div>
      <InputTitle
        style={{ fontFamily: "Noto Sans CJK KR", fontWeight: "400" }}
        name={"결제 수단 선택"}
        titleSize={16}
        addStyleToName={{ marginTop: "3px" }}
      />
      <div className={styles.means}>
        {Array.from(payMethodTable).map(([id, { key, name, img }]) => {
          if (typeof id === "number") return
          const selectStyle = {
            border: payMethod === id ? "2px solid red" : "",
          }
          const displayStyle = {
            display: id !== "card" && clicked.month === 0 ? "none" : "",
          }

          return (
            <div style={displayStyle} key={key}>
              <div id={id} style={selectStyle} onClick={() => setMethodDis(id)}>
                <img src={img} />
              </div>
              <div>{name}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MethodSelect
