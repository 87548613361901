import React, { useContext, useCallback, useEffect } from "react"
import dayjs from "dayjs"
import InputTitle from "components/tools/InputTitle.js"
import modalContext from "context/modalContext"
import * as styles from "./payments.module.css"
import { license } from "images"
import { textEdit } from "utils"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
const PayInfo = props => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const name = props.scbInfo.name ?? ""

  const { magazineInfo } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  console.log(props.selected_value?.isMagazine)
  const goods = new Map([
    [
      "name",
      {
        title: "구독 상품",
        // contents: props.selected_value?.title ?? "",
        contents:
          props.selected_value?.isMagazine === undefined
            ? ""
            : props.selected_value?.isMagazine
            ? "피치서가와 월간 피치서가 구독"
            : "피치서가 구독",
      },
    ],
    [
      "plan",
      {
        title: "구독 방식",
        contents: props.selected_value?.plan,
      },
    ],

    [
      "magazine",
      {
        title: "매거진",
        contents: props.selected_value?.isMagazine ? magazineInfo.title : "",
      },
    ],
    [
      "coupon",
      {
        title: "쿠폰",
        contents: props.coupon.title ?? "",
      },
    ],
  ])

  const payInfos = new Map([
    [
      "period",
      {
        title: "구독기간",
        contents: textEdit(props.selected_value?.period),
      },
    ],
    [
      "productPrice",
      {
        title: "상품금액",
        contents: getPrice(props.selected_value?.price),
      },
    ],
    [
      "salePrice",
      {
        title: "할인금액",
        contents: getPrice(props.selected_value?.salePrice),
      },
    ],
    [
      "payPrice",
      {
        title: "결제금액",
        contents: getPrice(props.selected_value?.finalPrice),
      },
    ],
  ])
  console.log(props.coupon.day)

  return (
    <>
      <div className={styles.stickyBox}>
        <InputTitle name={`${name}님의 구독 상품 정보`} titleSize={16} />

        <div className={styles.payInfo}>
          {Array.from(goods).map(([key, { title, contents }]) => {
            return (
              <div key={key}>
                <div>{title}</div>
                <div>{contents}</div>
              </div>
            )
          })}
        </div>

        <InputTitle name={`${name}님의 결제 정보`} titleSize={16} />

        <div className={styles.payInfo}>
          {Array.from(payInfos).map(([key, { title, contents }]) => {
            if (props.coupon.isTrial && key !== "period") {
              return
            } else {
              return (
                <div key={key} style={{ textAlign: "right" }}>
                  <div>{title}</div>
                  <div>{contents}</div>
                </div>
              )
            }
          })}
        </div>
        <div
          className={styles.redBtn}
          style={{
            margin: "0",
          }}
          onClick={() => {
            setModalMain(
              <Estimate
                onClose={() => {
                  setmodalOpen(false)
                }}
                coupon={props.coupon}
                goods={goods}
                payInfos={payInfos}
                scbInfo={props.scbInfo}
              />
            )
            setmodalOpen(true)
          }}
        >
          견적서 보기
        </div>
        <div className={styles.btnBox}>
          <div
            className={styles.redCircleBtn}
            onClick={() => {
              setmodalOpen(true)
              setModalMain(
                <div
                  className={styles.license}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  }}
                  onClick={() => {
                    setmodalOpen(false)
                  }}
                >
                  <img src={license} />
                </div>
              )
            }}
          >
            피치서가
            <br />
            사업자등록증
          </div>
          <div
            className={styles.redCircleBtn}
            style={{
              marginTop: "10px",
            }}
          >
            피치서가
            <br />
            리플렛
          </div>
        </div>
      </div>
    </>
  )
}
export default PayInfo

import Logo from "components/tools/logo.js"
import { getFooterAsync } from "store/modules/footerReducer.js"
import stamp from "images/stamp.png"

const Estimate = ({ onClose, goods, payInfos, scbInfo, coupon }) => {
  const modalStyle = {
    backgroundColor: "white",
    width: "980px",
    height: "700px",
    margin: "auto",
    marginTop: "100px",
    borderRadius: "10px",
    padding: "30px 100px",
  }
  const { contents: title } = goods.get("name")
  const { contents: month } = payInfos.get("period")
  const { contents: productPrice } = payInfos.get("productPrice")
  const { contents: salePrice } = payInfos.get("salePrice")
  const { contents: payPrice } = payInfos.get("payPrice")
  const vat = Math.floor(
    Number(payInfos.get("payPrice").contents.replace("원", "")) * 0.1
  )

  const today = dayjs().locale("ko").format("YYYY년 M월 D일")

  const onPrint = () => globalThis.print()

  const dispatch = useDispatch()

  const getFooter = useCallback(() => dispatch(getFooterAsync()), [dispatch])

  const { footer } = useSelector(
    ({ footerReducer: { data } }) => data,
    shallowEqual
  )

  const isUnTrial = coupon.day === 0

  useEffect(() => {
    getFooter()
  }, [])

  return (
    <div
      className={styles.license}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
      onClick={onClose}
    >
      <div style={modalStyle}>
        <div className={styles.flexCon}>
          <Logo w={105.38} h={23.2} />
          <div>견적일 {today}</div>
        </div>
        <div className={styles.flexCon}>
          <div>판매자 정보</div>
          <div className={styles.flexItem}>
            <div>{footer?.company_name}</div>
            <div>사업자등록번호: {footer?.company_reg_number}</div>
            <div>대표자: {footer?.company_ceo}</div>
            <div>소재지: {footer?.company_address}</div>
          </div>
        </div>
        <div className={styles.flexCon}>
          <div>구매자 정보</div>
          <div className={styles.flexItem}>
            <div>{scbInfo.name}</div>
            <div>청구주소: {scbInfo.address}</div>
            <div>구매자:{scbInfo.name}</div>
            <div>구매자 연락처: {scbInfo.phone}</div>
          </div>
        </div>
        <div>
          <div
            className={`${styles.flexCon} ${styles.flexMenu}`}
            style={{ borderBottom: "1px solid #dfdfdf" }}
          >
            <div>상품명</div>
            <div>구독기간</div>
            <div>{isUnTrial ? "상품금액" : null}</div>
            <div>{isUnTrial ? "할인금액" : null}</div>
            <div>{isUnTrial ? "결제금액" : null}</div>
          </div>
          <div className={`${styles.flexCon} ${styles.flexMenu}`}>
            <div>{title ?? ""}</div>
            <div>{month}</div>
            <div>{isUnTrial ? productPrice : null}</div>
            <div>{isUnTrial ? salePrice : null}</div>
            <div>
              {isUnTrial ? (
                <>
                  <div>{payPrice}</div>
                  <div>{payPrice ? `부가세 ${vat}원` : ""}</div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.flexCon}>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "10px",
              marginTop: "12px",
            }}
          >
            위와 같이 견적을 드립니다.
          </div>
          <img
            src={stamp}
            alt=""
            style={{
              width: "89px",
              height: "89px",
            }}
          />
        </div>
        <div onClick={onPrint} className={styles.printBtn}>
          인쇄하기
        </div>
      </div>
    </div>
  )
}

const getPrice = price => {
  console.log(price)
  if (price) {
    return `${price}원`
  } else if (price <= 0) {
    return `0원`
  } else {
    return ""
  }
}
