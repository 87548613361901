import React, { useContext, useEffect, useState, useCallback } from "react"
import * as styles from "./payments.module.css"
import InputTitle from "components/tools/InputTitle.js"
import Header from "./header.js"
import api from "apis"
import { getIsApp, getJson, getThumb, textEdit } from "utils"
// import {
//   setProduct,
//   setPlan,
//   setCoupon,
//   setIsAgree,
// } from "store/modules/payInfoReducer"
// import payObject from "utils/pay"
import PayCls from "class/payment"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
  ProductSelect,
  PlanSelect,
  CouponSelect,
  MethodSelect,
  PayInfo,
} from "components/payments"
import {
  setProductListAsync,
  setPayReset,
} from "store/modules/payInfoReducer.js"
import _ from "lodash"
import dayjs from "dayjs"
const Payments = () => {
  const dispatch = useDispatch()

  const { clicked, list, magazineInfo, payMethod, coupon } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )
  const profile = getJson("profile")
  const [scbInfo, setScbInfo] = useState({})

  useEffect(() => {
    ;(async () => {
      const scbInfo = await api.getscbDetail()

      await errorHandler(scbInfo)

      setScbInfo(scbInfo)

      dispatch(setProductListAsync())
    })()
    return () => dispatch(setPayReset())
  }, [])

  // 사용자가 선택한 상품정보
  const [selected_value, setSelected_value] = useState(null)

  useEffect(() => {
    if (clicked.magazine !== null && clicked.month !== null) {
      const select = Array.from(list.values()).find(
        ({ isMagazine, month }) =>
          isMagazine === Boolean(clicked.magazine) && month === clicked.month
      )

      if (select) {
        let finalPrice
        let salePrice = 0

        if (coupon) {
          if (coupon.discountRate > 0) {
            salePrice = Math.floor(select.price * (coupon.discountRate / 100.0))
            finalPrice = select.price - salePrice
          } else {
            salePrice = coupon.discount
            finalPrice = select.price - coupon.discount
          }
        }

        let period = select.period
        //정기구독시에 쿠폰프로세스
        if (
          coupon.number !== 0 &&
          (coupon.isTrial || (!select.isReg && !coupon.isTrial))
        ) {
          const begin = coupon.isTrial ? select.begin : select.end
          const end = dayjs
            .unix(begin)
            .add(coupon.day, "day")
            .format("YYYY년 M월 D일")

          period = `${dayjs
            .unix(select.begin)
            .format("YYYY년 M월 D일")}\n${end}`
        }

        setSelected_value({
          ...select,
          finalPrice: finalPrice > 0 ? finalPrice : 0,
          salePrice,
          period,
        })
      }
    }
  }, [clicked, coupon])

  const onPayment = _.debounce(async () => {
    //coupon.number === 0 이면 쿠폰 선택안한것
    // 빌링 메소드 99인지 확인

    let errorLog = {
      client_code: 0,
      client_body: "",
    }

    if (!isCheck()) {
      alert("약관에 동의해주셔야 결제를 진행 할 수 있습니다.")
      return
    } else if (await errorHandler(scbInfo)) {
      return
    } else if (!payMethod && !coupon.isTrial) {
      alert("결제수단을 선택해주세요.")

      return
    } else if (!selected_value) {
      alert("상품을 선택해 주세요.")
      return
    } else if (
      coupon.number !== 0 && // 쿠폰선택했고
      coupon?.couponSepar !== 0 && // 쿠폰이 전체 쿠폰이 아닐시
      selected_value?.couponSepar !== coupon?.couponSepar // 상품에 적용가능한 쿠폰이 아닐시
    ) {
      alert("해당 상품에 맞지않는 쿠폰입니다.")
      errorLog.client_body = {
        msg: "해당 상품에 맞지않는 쿠폰입니다.",
        profile,
      }

      await api.insertLog({
        body: errorLog,
      })
      return
    }

    console.log(coupon)
    // const validProduct = Array.from(list.values()).find(
    //   ({ couponSepar }) => couponSepar === coupon?.couponSepar
    // )

    // const msg = validProduct
    //   ? `${validProduct.title}상품 전용 쿠폰입니다.`
    //   : "현재 상품에 사용불가능한 쿠폰입니다."

    // alert(msg)

    if (coupon.isTrial) {
      const result = await api.payTrial({
        body: {
          billing_goods: selected_value.goods_no,
          billing_coupon: coupon.number,
        },
      })

      if (result) {
        alert("피치서가 이용쿠폰이 적용되었습니다")
        navigate(getIsApp() ? "/front" : "/subscription/success/")
      }
    } else {
      const blist = await api.scbHistory()

      const paymentCls = new PayCls(selected_value.isReg)

      // 초기화
      await paymentCls.init(blist)

      paymentCls.goods_no = selected_value.goods_no
      paymentCls.pay_method = payMethod
      paymentCls.name = selected_value.title
      paymentCls.amount = selected_value.finalPrice
      paymentCls.buyer_name = scbInfo.name
      paymentCls.buyer_tel = scbInfo.phone
      paymentCls.buyer_addr = scbInfo.address
      paymentCls.coupon_no = coupon.number

      await paymentCls.setting()

      if (!paymentCls.merchant_uid || !paymentCls.payment_no) {
        alert("일시적인 오류입니다. 다시 시도해주세요.")
        errorLog.client_body = {
          msg: "서버에서 결제정보를 받지않았습니다.",
          profile,
        }

        await api.insertLog({
          body: errorLog,
        })
        return
      } else if (paymentCls.isReg && !paymentCls.customer_uid) {
        alert("일시적인 오류입니다. 다시 시도해주세요.")
        errorLog.client_body = {
          msg: "정기구독은 customer_uid가 발급되어야합니다",
          profile,
        }
        await api.insertLog({
          body: errorLog,
        })

        return
      }

      await paymentCls.run()
    }
  }, 500)

  return (
    <div className={styles.con}>
      <Header title={"구독하실 상품을 선택해주세요."} />
      {/* 구독 상품 */}
      <ProductSelect />
      {/* 구독방식 */}
      <PlanSelect />
      {/* 쿠폰 */}

      {clicked.magazine ? (
        <div>
          <InputTitle
            style={{ fontFamily: "Noto Sans CJK KR", fontWeight: "400" }}
            name={"다음달 월간 피치서가"}
            titleSize={16}
            addStyleToName={{ marginTop: "3px" }}
          />
          <div
            className={styles.magazine}
            // style={{ justifyContent: "right", marginRight: "60px" }}
          >
            <div>
              <img src={getThumb(magazineInfo.uuid)} />
              <div className={styles.magText}>
                <div>{magazineInfo.title}</div>
                <div>{textEdit(magazineInfo.intro)}</div>
              </div>
            </div>
          </div>
          <div className={styles.magazineText}>
            월간 피치서가는 매월 첫째 주 월요일에 배송됩니다.
          </div>
        </div>
      ) : null}
      <CouponSelect />
      {/* 결제 수단 선택 */}
      {coupon.isTrial ? null : <MethodSelect />}
      {/* 구매 약관 동의 */}
      <Term />
      <div className={styles.redBtn} onClick={onPayment}>
        {coupon.isTrial ? (
          "피치서가 이용하기"
        ) : (
          <>
            {selected_value?.finalPrice
              ? `${selected_value.finalPrice}원 `
              : "00,000원 "}
            결제하기
          </>
        )}
      </div>
      <PayInfo
        selected_value={selected_value}
        scbInfo={scbInfo}
        coupon={coupon}
      />
    </div>
  )
}

export default Payments

const isCheck = () => {
  if (
    !(
      document.getElementById("termPersoner").checked &&
      document.getElementById("termProduct").checked
    )
  ) {
    return false
  }

  return true
}

import CheckBox from "components/tools/checkBox.js"
import modalContext from "context/modalContext"
import { term } from "utils/data"
import { getTermContents } from "utils"
import BottomBtnModal from "components/tools/modalComponents/bottomBtnModal"
import { navigate } from "gatsby"
import { getProfile } from "store/modules/profileReducer"

const Term = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const termIdx = 9 // 개인정보 3자 제공동의
  const [termContents, setTermContents] = useState({})

  useEffect(() => {
    ;(async () => {
      const result = await api.getTermDetail(termIdx)
      console.log(result)
      if (result && result[0].length)
        setTermContents({
          key: result[0].kind,
          term_no: result[0].term_no,
          title: result[0].title,
          contents: getTermContents(result[0].TermContent),
        })
    })()
  }, [])

  return (
    <div>
      <InputTitle
        style={{ fontFamily: "Noto Sans CJK KR", fontWeight: "400" }}
        name={"구매 약관 동의"}
        titleSize={16}
        addStyleToName={{ marginTop: "3px" }}
      />
      <div className={styles.checkList}>
        <div>
          <CheckBox
            id={`termAll`}
            name={"checkbox"}
            onClick={({ target: { checked } }) => {
              document
                .getElementsByName("checkbox")
                .forEach(v => (v.checked = checked))
            }}
            className={"circleCheckBox"}
          />
          <div style={{ fontWeight: 500 }}>약관 전체 동의</div>
        </div>
        <hr />
        <div>
          <CheckBox
            id={`termPersoner`}
            name={"checkbox"}
            className={"circleCheckBox"}
          />
          <div style={{ fontWeight: "400" }}>개인정보 제 3자 제공 동의</div>
          <div
            className={styles.termDetail}
            onClick={() => {
              setmodalOpen(true)
              setModalMain(
                <BottomBtnModal
                  close={() => {
                    setmodalOpen(false)
                  }}
                  size={{
                    w: 430,
                    h: 590,
                  }}
                  confirm="확인"
                >
                  <div className={styles.termBox}>
                    <div>{termContents.title}</div>
                    <div>{termContents.contents}</div>
                  </div>
                </BottomBtnModal>
              )
            }}
          >
            <span style={{ fontWeight: 400 }}> 자세히 보기</span>
          </div>
        </div>

        <hr />
        <div>
          <CheckBox
            id={`termProduct`}
            name={"checkbox"}
            className={"circleCheckBox"}
          />
          <div style={{ fontWeight: "400" }}>
            위 상품 정보 및 거래조건을 확인하였으며, 구매진행에 동의합니다.
          </div>
        </div>
      </div>
    </div>
  )
}

const errorHandler = async scbInfo => {
  if (!scbInfo.owner) {
    if (await confirm("단체회원은 그룹장만 결제가 가능합니다.")) {
      navigate(-1)
    }
    return true
  } else if (!scbInfo.isProfile) {
    if (
      await confirm("구독정보가 없는 회원입니다. 구독정보를 먼저 입력해주세요")
    ) {
      navigate("/subscription/")
    }
    return true
  } else if (scbInfo.isScb) {
    if (await confirm("현재 구독중인 회원은 중복결제할 수 없습니다.")) {
      navigate("/front")
    }
    return true
  } else if (scbInfo.isRefund) {
    if (await confirm("관리자가 환불을 승인해야 결제할 수 있습니다")) {
      navigate("/front")
    }
    return true
  } else {
    return false
  }
}
