import React, { useCallback } from "react"
import InputTitle from "components/tools/InputTitle.js"
import * as styles from "./payments.module.css"
import { product_x, product_12, product_6 } from "images"
import { setMonth } from "store/modules/payInfoReducer"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
const PlanSelect = () => {
  const dispatch = useDispatch()

  const setMonthDis = useCallback(
    value => {
      dispatch(setMonth(value))
    },
    [dispatch]
  )

  const { clicked, list } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  console.log(clicked?.magazine)

  const kindList = [...new Set(Array.from(list).map(([, { month }]) => month))]

  return (
    <div>
      <InputTitle
        style={{ fontFamily: "Noto Sans CJK KR", fontWeight: "400" }}
        name={"구독 방식 선택"}
        titleSize={16}
        addStyleToName={{ marginTop: "3px" }}
      />
      <div className={styles.plan}>
        {kindList.map((key, i) => {
          const value = staticTable.get(key)
          const style = {
            border: clicked.month === value.month ? "2px solid #E95C63" : "",
          }
          return clicked.magazine &&
            (value.month === 6 || value.month === 12) ? null : (
            <div onClick={() => setMonthDis(value.month)} style={style} key={i}>
              <div>
                <div>{value.exp}</div>
                <div>{value.title}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PlanSelect
const staticTable = new Map([
  [
    0,
    {
      month: 0,
      img: product_x,
      exp: "매달 자동으로 요금이 결제되는",
      title: "피치서가 정기구독",
    },
  ],
  [
    6,
    {
      month: 6,
      img: product_6,
      exp: "한 번의 결제로 6개월간 피치서가를 이용할 수 있는",
      title: "피치서가 6개월 구독",
    },
  ],
  [
    12,
    {
      month: 12,
      img: product_12,
      exp: "한 번의 결제로 12개월간 피치서가를 이용할 수 있는",
      title: "피치서가 12개월 구독",
    },
  ],
])
