import React, { useContext, useState, useCallback, useEffect } from "react"
import modalContext from "context/modalContext"
import * as styles from "./payments.module.css"
import InputTitle from "components/tools/InputTitle.js"
import storyRegist from "images/storyRegist.png"
import modalClose from "images/modalClose.png"
import api from "apis"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { setCoupon, setCouponReset } from "store/modules/payInfoReducer"
const CouponSelect = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [coupnList, setCouponList] = useState([])

  useEffect(() => {
    ;(async () => {
      const result = await api.getCoupon()
      console.log(result)
      if (result) setCouponList(result)
    })()
  }, [])

  const dispatch = useDispatch()

  const selectCoupon = useCallback(
    value => {
      dispatch(
        setCoupon({
          day: value.day,
          discount: value.discount,
          discountRate: value.discountRate,
          number: value.number,
          title: value.title,
          isTrial: value.isTrial,
          couponSepar: value.couponSepar,
        })
      )
    },
    [dispatch]
  )

  const coupon = useSelector(
    ({ payInfoReducer: { product } }) => product.coupon,
    shallowEqual
  )

  const onCoupon = () => {
    setmodalOpen(true)
    setModalMain(
      <div className={styles.couponBox}>
        <div>
          <div>나의 쿠폰함</div>
          <img
            src={modalClose}
            alt=""
            onClick={() => {
              setmodalOpen(false)
            }}
          />
        </div>
        <div>
          {coupnList.map((v, i) => (
            <div className={styles.couponItem} key={i}>
              <div>{v.title}</div>
              <div
                onClick={() => {
                  selectCoupon(v)
                  setmodalOpen(false)
                }}
              >
                사용
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div>
      <InputTitle
        name={"쿠폰"}
        titleSize={16}
        style={{ fontFamily: "Noto Sans CJK KR", fontWeight: "400" }}
        addStyleToName={{ marginTop: "3px" }}
      />
      <div className={styles.couponCon}>
        <div className={styles.coupon}>
          {coupon?.title ? (
            <div>{coupon.title}</div>
          ) : (
            <img src={storyRegist} alt="" onClick={onCoupon} />
          )}
        </div>
        <div onClick={() => dispatch(setCouponReset())}>취소</div>
      </div>
    </div>
  )
}
export default CouponSelect
