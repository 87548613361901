import React, { useCallback, useEffect } from "react"
import InputTitle from "components/tools/InputTitle.js"
import * as styles from "./payments.module.css"
import { online_magazine, online } from "images"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectMegazine } from "store/modules/payInfoReducer"
// import {
//   setProduct,
//   setPlan,
//   setCoupon,
//   setIsAgree,
// } from "store/modules/payInfoReducer"
// import { useMenu } from "hooks"
// import { usePayMenu } from "hooks"

const ProductSelect = () => {
  const dispatch = useDispatch()

  const selectMegazineDis = useCallback(
    value => {
      dispatch(selectMegazine(value))
    },
    [dispatch]
  )

  const { clicked, list } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  const kindList = [
    ...new Set(Array.from(list).map(([, { isMagazine }]) => isMagazine)),
  ]

  console.log(clicked)

  return (
    <div>
      <InputTitle
        style={{ fontFamily: "Noto Sans CJK KR", fontWeight: "400" }}
        name={"구독 상품 선택"}
        titleSize={16}
        addStyleToName={{ marginTop: "3px" }}
      />
      <div className={styles.product}>
        {[false].map((key, i, { length }) => {
          const value = staticTable.get(key)
          const style = {
            border:
              clicked.magazine === value.magazine ? "2px solid #E95C63" : "",
          }
          return (
            <div
              name={"product"}
              key={i}
              style={style}
              onClick={() => selectMegazineDis(value.magazine)}
            >
              <img src={value.img} />
              <div style={i === 0 ? { marginTop: "1.5px" } : null}>
                {value.title}
              </div>
              <div
                style={
                  i === 0 ? { marginTop: "0.5px", marginBottom: "20px" } : null
                }
              >
                {value.exp}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductSelect

const staticTable = new Map([
  [
    false,
    {
      magazine: 0,
      img: online,
      exp: "온라인에서 무제한으로 스토리 보기",
      title: "피치서가 구독",
    },
  ],
  [
    true,
    {
      magazine: 1,
      img: online_magazine,
      exp: (
        <span>
          온라인 구독과 함께 <br />
          매달 월간 피치서가 1권 받아보기
        </span>
      ),
      title: "피치서가와 월간 피치서가 구독",
    },
  ],
])
